<template>
  <div>
    <Row :gutter="8" class="m-b-10">
      <i-col span="10">
        <Input size="small" placeholder="关键词：资源编号" v-model="query.keyword" />
      </i-col>
      <i-col span="6">
        <Button type="primary" icon="ios-search" class="m-r-5" size="small" @click="handleSearch">搜索</Button>
        <!-- <Button type="success" size="small" class="m-r-5" :disabled="selectedItems.length === 0"
          @click="handelSubmit(1)">确认撤销</Button>
        <Button type="error" size="small" class="m-r-5" :disabled="selectedItems.length === 0"
          @click="handelSubmit(2)">拒绝撤销</Button> -->
      </i-col>
    </Row>
    <div>
      <Table stripe max-height="720" size="small" :data="list" :columns="tableColumns" :loading="tableLoading"></Table>
      <div class="paging_style">
        <Page :total="total" size="small" :page-size="query.pageSize" show-total show-elevator show-sizer
          :page-size-opts="pagesizeSetting" @on-page-size-change='changePageSize' :current="query.pageNumber"
          @on-change="handleChangePage"></Page>
      </div>
    </div>
  </div>
</template>

<script>
import { formartMspTaskitemStatus } from '@/utils/tagStatus'
import { getHangUpTaskitemPage, setHangUpTaskitemFinish, unHangUpTaskitem } from '@/api/msp/hangup'
export default {
  data () {
    return {
      pagesizeSetting: [15, 50, 100, 200, 500],
      query: {
        pageNumber: 1,
        pageSize: 15,
        keyword: ''
      },
      total: 0,
      list: [],
      tableLoading: false,
      tableColumns: [
        { title: '任务名称', key: 'taskName', minWidth: 100, align: 'center' },
        { title: '任务类型', key: 'typeName', minWidth: 100, align: 'center' },
        { title: '资源编号', key: 'code', minWidth: 100, align: 'center' },
        { title: '站点', key: 'stationName', minWidth: 100, align: 'center' },
        { title: '作业日期', key: 'actionDate', minWidth: 100, align: 'center' },
        {
          title: '状态',
          key: 'statusName',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            const html = [
              formartMspTaskitemStatus(h, params.row.status, params.row.statusName)
            ]
            if (params.row.hangUpStatus === 2) { // 挂起
              html.push(h('div', [
                h('Tag', {
                  props: {
                    color: 'warning'
                  }
                }, '已挂起')
              ]))
            }
            return h('div', html)
          }
        },
        {
          title: '操作',
          minWidth: 100,
          align: 'center',
          render: (h, { row }) => {
            const html = []
            // 派单中、 已派单、执行中可解除挂起操作
            if ([0, 1, 2].includes(row.status)) {
              html.push(
                h('a', {
                  style: { display: 'block' },
                  on: {
                    click: () => {
                      this.secureHangupStatus(row)
                    }
                  }
                }, '解除挂起'))
              if (row.type === 3 && row.status === -1) { // 制作任务不执行状态
                html.push(h('a', {
                  style: { display: 'block' },
                  on: {
                    click: () => {
                      this.setTaskStatusComplete(row)
                    }
                  }
                }, '标注已完成'))
              }
            }
            return h('div', html)
          }
        }
      ]
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.tableLoading = true
      getHangUpTaskitemPage(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.list
          this.total = res.totalRow
          this.query.pageNumber = res.pageNumber
        } else {
          this.list = []
          this.total = 0
          this.query.pageNumber = 1
        }
      }).finally(() => { this.tableLoading = false })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.initData()
    },
    handleChangePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    setTaskStatusComplete (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要将该任务标注为已完成？',
        onOk: () => {
          setHangUpTaskitemFinish({ taskitemId: params.taskitemId }).then(res => {
            if (res && res.errcode === 0) {
              this.initData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    secureHangupStatus (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要将该任务解除挂起？',
        onOk: () => {
          unHangUpTaskitem({ taskitemId: params.taskitemId }).then(res => {
            if (res && res.errcode === 0) {
              this.initData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    }
  }
}
</script>
